<template>
  <div class="row today-info">
    <div class="col-12 col-lg-4 mb-3">
      <div class="rounded-xl h-100 p-5 info-card">
        <div
          class="info-card-loading"
          v-if="infoData == null || !infoData.ready"
        >
          <i class="fas fa-spinner fa-pulse"></i>
        </div>
        <table class="table table-sm table-borderless" v-else>
          <tr v-for="(item, index) in infoData.header" :key="item.id">
            <th>{{ item }}</th>
            <td>:</td>
            <td>{{ infoData.data[index] }}</td>
          </tr>
        </table>
      </div>
    </div>
    <div class="col-12 col-lg-4 mb-3">
      <div class="rounded-xl h-100 p-5 today product-card">
        <div class="product-header">
          <div class="svg-icon svg-icon-4x svg-icon-white">
            <inline-svg :src="svgSrc" />
          </div>
          <p>Bugünün Üretimi</p>
        </div>
        <div class="info-content mt-5">
          <p v-if="todayData != ''">
            {{ todayData }}
          </p>
          <i class="fas fa-spinner fa-pulse" v-else></i>
        </div>
      </div>
    </div>
    <div class="col-12 col-lg-4 mb-3">
      <div class="rounded-xl h-100 p-5 total product-card">
        <div class="product-header">
          <div class="svg-icon svg-icon-4x svg-icon-white">
            <inline-svg :src="svgSrc" />
          </div>
          <p>Toplam Üretim</p>
        </div>
        <div class="info-content mt-5">
          <p v-if="totalData != ''">
            {{ totalData }}
          </p>
          <i class="fas fa-spinner fa-pulse" v-else></i>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
$card-text-color: white;
$info-card-bg: #00bcd4;
$today-product-card-bg: #f27935;
$total-product-card-bg: #9a12b3;
$wait-size:5rem;
$product-header-size:2.25rem;
.today-info {
  color: $card-text-color;
  .col-12{
    min-height: 210px;
  }
  .info-card {
    color: $card-text-color;
    background: $info-card-bg;
    display: flex;
    justify-content: center;
    align-items: center;
    table {
      color: $card-text-color;
      font-size: 1.2rem;
    }
    i{
      font-size: $wait-size;
      color: $card-text-color;
    }
  }
  .today {
    color: $card-text-color;
    background: $today-product-card-bg;
  }
  .total {
    color: $card-text-color;
    background: $total-product-card-bg;
  }
  .product-card {
    .product-header {
      display: flex;
      justify-content: start;
      align-items: center;
      p{
        font-size: $product-header-size;
      }
    }
    .info-content{
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100px;
      p{
        font-size: 3.75rem;
        font-weight: 600;
        text-align: center;
      }
      i{
        font-size: $wait-size;
        color: $card-text-color;
      }
    }
  }
}
@media screen and (max-width:1350px) and (min-width:950px) {
  .product-card {
    .product-header {
      p{
        font-size: $product-header-size - 0.75rem!important;
      }
    }
    .info-content{
      p{
        font-size: 2.75rem!important;
      }
    }
  }
  
}
</style>

<script>
export default {
  name: "today_ınfo",
  props: ["infoData", "todayData", "totalData"],
  data() {
    return {
      svgSrc: require("/public/media/svg/icons/Electric/Highvoltage.svg"),
    };
  },
};
</script>
